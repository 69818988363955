import * as React from 'react';

import { Grid, Typography, Button } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Container from '../components/Container';
import SEO from '../components/seo';
import Hero from '../components/Hero';
import PortfolioList from '../components/portfolio/PortfolioList';
import ArticleList from '../components/articles/ArticleList';
import muiTheme from '../config/theme';

const WrapLink = styled.a`
  text-decoration: none;
  color: ${muiTheme.palette.primary.light};

  &:hover {
    text-decoration: underline;
    color: ${muiTheme.palette.primary.main};
  }
`;

const IndexPage: React.FC = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <Grid container direction="column" justify="center">
        <Grid item xs={12}>
          <Hero gatsbySharpImage={data?.headerImage} />
        </Grid>

        <div style={{ marginTop: '4rem' }} />

        <Grid item xs={12}>
          <Container variant="wide">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8} md={6}>
                <Typography
                  variant="h1"
                  component="h2"
                  color="primary"
                  gutterBottom
                >
                  Projects
                </Typography>
                <Typography variant="body1">
                  I've worked both as an employee, and as a freelancer. These
                  are my favourite projects.
                </Typography>
                <div style={{ marginTop: '2rem' }} />
              </Grid>
              <Grid item xs={12}>
                <PortfolioList maxPosts={3} />
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <div style={{ marginTop: '4rem' }} />

        <Grid item xs={12} style={{ background: '#f9fafe', padding: '2rem 0' }}>
          <Container variant="wide">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8} md={6}>
                <Typography
                  variant="h1"
                  component="h2"
                  color="primary"
                  gutterBottom
                >
                  Blog
                </Typography>
                <Typography variant="body1">
                  In my spare time I create other... things. Usually there's
                  some type of programming, hardware or 3D printing involved.
                  These are the projects I'm proud to share.
                </Typography>
                <div style={{ marginTop: '2rem' }} />
              </Grid>
              <Grid item xs={12}>
                <ArticleList maxPosts={3} />
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <div style={{ marginTop: '4rem' }} />

        <Grid item>
          <Container variant="wide">
            <Grid container direction="row">
              <Grid item xs={12} sm={8} md={6} style={{ marginBottom: '2rem' }}>
                <Typography variant="h5" component="p" color="textSecondary">
                  Hey I just met you...
                </Typography>
                <div id="contact" />
                <Typography variant="h2" gutterBottom>
                  Call me, maybe?
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Or you can{' '}
                  <WrapLink href="https://twitter.com/nilswesthoff">
                    Tweet
                  </WrapLink>{' '}
                  me,{' '}
                  <WrapLink href="https://t.me/nilswesthoff">Telegram</WrapLink>{' '}
                  me,{' '}
                  <WrapLink href="mailto:nils@nilswesthoff.com">
                    e-mail
                  </WrapLink>{' '}
                  me. Alternatively, you can look up what I do on{' '}
                  <WrapLink href="https://www.dribbble.com/nilswesthoff/">
                    {' '}
                    Dribbble
                  </WrapLink>
                  ,{' '}
                  <WrapLink href="https://www.instagram.com/nilswesthoff/">
                    Instagram
                  </WrapLink>
                  ,{' '}
                  <WrapLink href="https://www.linkedin.com/in/nilswesthoff/">
                    LinkedIn
                  </WrapLink>
                  ,{' '}
                  <WrapLink href="https://github.com/nwesthoff">
                    GitHub
                  </WrapLink>{' '}
                  and{' '}
                  <WrapLink href="https://www.thingiverse.com/nwesthoff/">
                    Thingiverse
                  </WrapLink>
                  . You can also find an article about Chatbots on{' '}
                  <WrapLink href="https://medium.com/@nilswesthoff">
                    Medium
                  </WrapLink>{' '}
                  by me.
                </Typography>
                <Grid container spacing={1}>
                  <Grid item>
                    <Button
                      variant="text"
                      size="large"
                      href="mailto:nils@nilswesthoff.com"
                    >
                      <EmailIcon style={{ marginRight: '.4rem' }} />
                      send email
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="text" size="large" href="tel:+31634279575">
                      <PhoneIcon style={{ marginRight: '.4rem' }} />
                      Call
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IndexPage;
