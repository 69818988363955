import * as React from 'react';
import { Link, graphql, StaticQuery, navigate } from 'gatsby';
import styled from 'styled-components';
import { Typography, Button, Grid } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import Img from 'gatsby-image';
import { ArticleListQueryQuery } from '../../../types/graphql-types'; // eslint-disable-line import/no-unresolved
import muiTheme from '../../config/theme';

const PostImage = styled(Img)`
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
`;

const WrapLink = styled(Link)`
  text-decoration: none;
  color: ${muiTheme.palette.text.primary};

  .gatsby-image-wrapper {
    transition: all 450ms ease-in-out;
  }

  &:hover {
    color: ${muiTheme.palette.primary.light};

    .gatsby-image-wrapper {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    }
  }
`;

interface Props {
  maxPosts?: number;
}

const ArticleListQuery = graphql`
  query ArticleListQuery {
    allContentfulBlogPost(limit: 10) {
      edges {
        node {
          id
          title
          slug
          updatedAt
          excerpt {
            excerpt
          }
          featuredImage {
            fluid(maxWidth: 520) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const ArticleList: React.FC<Props> = ({ maxPosts }: Props) => (
  <Grid container spacing={4}>
    <StaticQuery
      query={ArticleListQuery}
      render={({ allContentfulBlogPost }: ArticleListQueryQuery) =>
        allContentfulBlogPost.edges?.slice(0, maxPosts).map(({ node }) => (
          <Grid item xs={12} md={6} lg={4} key={node.id}>
            <article>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                {node.featuredImage ? (
                  <Grid item key={node.id} xs={12}>
                    <WrapLink to={`/blog/${node.slug}`}>
                      <PostImage
                        alt={node.title || ''}
                        fluid={node.featuredImage.fluid}
                      />
                    </WrapLink>
                  </Grid>
                ) : null}

                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <WrapLink to={`/blog/${node.slug}`}>
                        <Typography variant="h3" gutterBottom>
                          {node.title}
                        </Typography>
                      </WrapLink>
                    </Grid>

                    <Grid item>
                      <Typography variant="body1" gutterBottom>
                        {node.excerpt?.excerpt}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="text"
                        size="large"
                        onClick={() => {
                          navigate(`/blog/${node.slug}`);
                        }}
                      >
                        <NotesIcon style={{ marginRight: '.4rem' }} />
                        Read More
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </article>
          </Grid>
        ))
      }
    />
  </Grid>
);

export default ArticleList;
