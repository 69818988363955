import * as React from 'react';
import { Link, graphql, StaticQuery, navigate } from 'gatsby';
import styled from 'styled-components';
import { Typography, Button, Grid, Hidden } from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import Img from 'gatsby-image';
import Lottie from 'react-lottie';
import { Parallax } from 'react-scroll-parallax';
import { PortfolioListQueryQuery } from '../../../types/graphql-types';
import muiTheme from '../../config/theme';

const StyledLottie = styled.div`
  position: absolute;
  top: -25px;
  right: -25px;
  fill: ${muiTheme.palette.text.secondary};
`;

const PostImage = styled(Img)`
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
`;

const WrapLink = styled(Link)`
  text-decoration: none;
  color: ${muiTheme.palette.text.primary};

  .gatsby-image-wrapper {
    transition: all 450ms ease-in-out;
  }

  &:hover {
    color: ${muiTheme.palette.primary.light};

    .gatsby-image-wrapper {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    }
  }
`;

interface Props {
  maxPosts?: number;
}

const PortfolioListQuery = graphql`
  query PortfolioListQuery {
    allContentfulProject(limit: 3) {
      edges {
        node {
          id
          title
          subtitle
          slug
          excerpt {
            excerpt
          }
          hoverAnimation {
            file {
              url
            }
          }
          updatedAt
          featuredImage {
            fluid(maxWidth: 520) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const PortfolioList: React.FC<Props> = ({ maxPosts }: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Grid container spacing={4}>
      <StaticQuery
        query={PortfolioListQuery}
        render={({ allContentfulProject }: PortfolioListQueryQuery) =>
          allContentfulProject.edges?.slice(0, maxPosts).map(({ node }, i) => {
            return (
              <Grid item key={node.id} xs={12} sm={6} md={12}>
                <Hidden smDown>
                  <div style={{ marginBottom: '3rem' }} />
                </Hidden>
                <article>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    direction={i % 2 === 0 ? 'row' : 'row-reverse'}
                    spacing={2}
                  >
                    {node.featuredImage?.fluid ? (
                      <Grid
                        item
                        key={node.id}
                        xs={12}
                        md={7}
                        style={{ position: 'relative' }}
                      >
                        <WrapLink to={`/portfolio/${node.slug}`}>
                          <PostImage
                            alt={node.title || ''}
                            fluid={node.featuredImage.fluid}
                          />
                        </WrapLink>
                        <StyledLottie>
                          <Parallax y={[30, -30]} tagOuter="figure">
                            <Lottie
                              options={{
                                path: node.hoverAnimation?.file?.url,
                                ...defaultOptions,
                              }}
                              height={150}
                              width={150}
                            />
                          </Parallax>
                        </StyledLottie>
                      </Grid>
                    ) : null}

                    <Grid item xs={12} md={4}>
                      <Grid container direction="column">
                        <Grid item>
                          <WrapLink to={`/portfolio/${node.slug}`}>
                            <Typography variant="h2" component="h3">
                              {node.title}
                            </Typography>
                          </WrapLink>
                          <Typography
                            color="textSecondary"
                            variant="h5"
                            component="h4"
                            gutterBottom
                          >
                            {node.subtitle}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography variant="body1" gutterBottom>
                            {node.excerpt?.excerpt}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Button
                            variant="text"
                            size="large"
                            onClick={() => {
                              navigate(`/portfolio/${node.slug}`);
                            }}
                          >
                            <NotesIcon style={{ marginRight: '.4rem' }} />
                            Read More
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </article>
                <div style={{ marginBottom: '3rem' }} />
              </Grid>
            );
          })
        }
      />
    </Grid>
  );
};

export default PortfolioList;
