import * as React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import styled from 'styled-components';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Container from './Container';
import muiTheme from '../config/theme';
import LogoVanBerlo from '../images/logos/vanberlo-logo.svg';
import LogoDCC from '../images/logos/dcc-logo.svg';
import LogoSvid from '../images/logos/svid-logo.svg';

const HeroContainer = styled.div`
  position: relative;
  display: flex;
  min-height: 80vh;
`;

const GatsbyHeroImage = styled.div`
  position: absolute;
  object-fit: cover;
  object-position: center;
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -3;
  transition: opacity 250ms ease-in-out;

  & > div {
    height: 100%;
  }

  @media screen and (max-width: ${muiTheme.breakpoints.width('md')}px) {
    opacity: 0.4;
  }
`;

const LogoGrid = styled(Grid)`
  &&& {
    padding-bottom: ${muiTheme.spacing(2)}px;
  }

  img {
    opacity: 0.6;
    transition: opacity 120ms ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
`;

const Logo = styled(Grid)`
  max-width: 120px;
  width: 20%;
  height: 65px;
  position: relative;

  & img {
    position: absolute;
    object-position: center center;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`;

const Hero: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "header-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <HeroContainer>
      <GatsbyHeroImage>
        <Img fluid={data.heroImage.childImageSharp.fluid} />
      </GatsbyHeroImage>
      <Container variant="wide">
        <Grid
          container
          direction="column"
          justify="space-between"
          wrap="nowrap"
          style={{ height: '100%', zIndex: 100 }}
        >
          <div style={{ marginTop: '3rem' }} />

          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  variant="body1"
                  style={{ fontSize: '1.4em', maxWidth: '570px' }}
                  gutterBottom
                >
                  <b>Hi, I’m Nils</b> – a designer and creative developer. With
                  a background in industrial design and a passion for software,
                  I aim to explore where the physical and digital world meet. To
                  enable new experiences.
                </Typography>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Button variant="text" href="#contact" color="primary">
                      Contact
                      <NavigateNextIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <LogoGrid item>
            <Typography variant="overline">I worked for</Typography>
            <Grid container alignItems="center" wrap="nowrap">
              <Logo item>
                <a href="https://vanberloagency.com" target="blank">
                  <img alt="VanBerlo Agency Logo" src={LogoVanBerlo} />
                </a>
              </Logo>
              <Logo item>
                <a href="https://dutchcodingcompany.nl" target="blank">
                  <img alt="Dutch Coding Company Logo" src={LogoDCC} />
                </a>
              </Logo>
              <Logo item>
                <a href="https://svid.nl" target="blank">
                  <img alt="ID Study Association Logo" src={LogoSvid} />
                </a>
              </Logo>
            </Grid>
          </LogoGrid>
        </Grid>
      </Container>
    </HeroContainer>
  );
};

export default Hero;
